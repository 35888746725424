<script setup>
import pickBy from "lodash/pickBy";
import { defineProps, computed } from "vue";
import vatRegistrationCountries from "./vatRegistrationCountries.json";
const props = defineProps(["formData"]);
const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);

const vatRegistrationCountryOptions = computed(
  () => pickBy(vatRegistrationCountries, (c, key) => {return key != headOfficeCountry.value} )
)
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
import CountryFlag from 'vue-country-flag-next';
export default {
  name: "ExistingRegistrationCountries",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="existing.VAT.registration.countries"
    name="existing.VAT.registration.countries"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Countries with existing VAT Registrations</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="Select the countries with existing VAT Registrations"
          name="list"
          id="list"
          :options="vatRegistrationCountryOptions"
        >
        <template #label="context"><country-flag :country="context.option.value" style="border: 1px solid #000; margin-top: -9px;"/> &nbsp; {{ context.option.label }}</template>
        </FormKit>
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
