<script setup>
import { defineProps, computed } from "vue";
import countries from "./countries.json";
import regions from "./regions.json";
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
const props = defineProps(["formData"]);
const regionsTest = computed(
  () => {
    let country = props.formData.onboardingFormSteps.step08["bank.information"][
      "country"
    ]
    return regions[country] ? regions[country] : [];
  }
);

function usedRegions({search}){
  let country = props.formData.onboardingFormSteps.step08["bank.information"][
      "country"
    ]
  if(!country){
    return [];
  } else {
      if(!search){
        return regions[country] ? regions[country] : []
      } else {
        if(!regions[country] ){
          return [];
        }
        return filter(regions[country], (a) =>  {
          return new RegExp(search, 'gi').test(a)});
      }
  }
}
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "BankInformation",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="bank.information"
    name="bank.information"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Bank Information</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Account owner"
          name="account.owner"
          id="account.owner"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="text"
          label="Name of bank"
          name="bank.name"
          id="bank.name"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="IBAN"
          name="account.IBAN"
          id="account.IBAN"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="text"
          label="BIC"
          name="account.BIC"
          id="account.BIC"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Street"
          name="street.name"
          id="street.name"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Street No."
          name="street.number"
          id="street.number"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Additional info"
          name="street.additional.info"
          id="street.additional.info"
          validation=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4">
        <FormKit
          type="text"
          label="Zip code"
          name="zip.code"
          validation="required"
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="text"
          label="City"
          name="city.name"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="autocomplete"
          label="Country"
          placeholder="Select the country"
          name="country"
          clear-search-on-open
          :options="countries"
          validation="required"
        />
      </el-col>
      <el-col :span="12" v-if="!isEmpty(regionsTest)">
        <FormKit
          type="autocomplete"
          label="Region"
          placeholder="Select the region"
          name="region"
          :options="usedRegions"
          clear-search-on-open
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="tel"
          label="Phone number"
          name="phone.number"
          validation="required"
        >
        </FormKit>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <h2>Issuance of SEPA direct debit mandate</h2>
        <p>
          <strong>SEPA Information about DutyPay</strong><br />
          Dutypay GmbH, Pirmasenser Straße 1, 67655 Kaiserslautern<br />
          <strong>Creditor Identification Number:</strong>
          DE29DPE00001676383<br />
        </p>
        <p>
          I / We authorize DutyPay GmbH to collect payments from my / our account by direct debit. At the same time, I instruct my bank to redeem the direct debits drawn by DutyPay GmbH on my / our account.
        </p>
        <p>
          <strong>Note:</strong> I / We can claim reimbursement of the amount debited within eight weeks of the debit date. The conditions agreed with my / our bank apply.
        </p>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <FormKit
          type="date"
          label="Begin of SEPA direct debit mandate"
          name="SEPA.mandate.startDate"
          id="SEPA.mandate.startDate"
          validation="required"
          :value="new Date().toISOString().split('T')[0]"
          readonly
        />
      </el-col>
      <el-col :span="16">
        <FormKit
          type="text"
          label="SEPA direct debit mandate authorizing person"
          name="SEPA.mandate.authorizing.person"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-alert
          title="Notice for use in tax registrations"
          type="info"
          description="These bank details will be used as an information for registrations with tax authorities. There will be no direct debit by tax authorities without further notice."
          show-icon
          :closable="false"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss">
.el-alert {
  margin: 0 0 20px;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
