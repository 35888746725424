<script setup>
import { defineProps, computed } from "vue";
import {includes, omitBy} from "lodash";
import vatRegistrationCountries from "./vatRegistrationCountries.json";
const props = defineProps(["formData"]);

const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);

const existingRegistrationCountries = computed(
  () => 
    props.formData.onboardingFormSteps.step09["existing.VAT.registration.countries"][
      "list"
    ]
)

const desirableRegistrationCountries = computed(
  () => omitBy(vatRegistrationCountries, (c, key) => {
    return includes(existingRegistrationCountries.value, key) || key == headOfficeCountry.value
  })

)
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
import CountryFlag from "vue-country-flag-next";
export default {
  name: "RegistrationCountries",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="desired.VAT.registration.countries"
    name="desired.VAT.registration.countries"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Countries for planned VAT registration</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="Select the countries for your planned VAT registration."
          name="list"
          id="list"
          :options="desirableRegistrationCountries"
        >
        <template #label="context"><country-flag :country="context.option.value" style="border: 1px solid #000; margin-top: -9px;"/> &nbsp; {{ context.option.label }}</template>
        </FormKit>
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
