<script setup>
import { defineProps, computed } from "vue";
import vatRegistrationCountries from "./vatRegistrationCountries.json";
const props = defineProps(["formData"]);
const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "OSSRegistration",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="oss.registration"
    name="oss.registration"
    #default="{ value }"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          OSS Registration in {{ vatRegistrationCountries[headOfficeCountry] }}
        </h1>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-alert
          title="Registration Notice"
          type="info"
          show-icon
          :closable="false"
          description="Please note that an OSS registration requires at least a valid Tax ID and VAT ID in the country of your head office."
          style="margin: 1rem 0 1rem"
        >
        </el-alert>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="The company is already registered for OSS"
          help="Check if you already have an active registration for OSS."
          name="isOSSRegistered"
          id="isOSSRegistered"
          :value="false"
        />
      </el-col>
    </el-row>

    <el-row v-if="value.isOSSRegistered">
      <el-col :span="24">
        <el-col :span="6">
        <FormKit
          type="date"
          label="OSS Registration Date"
          name="registration.startDate"
          id="registration.startDate"
          validation=""
        />
      </el-col>
      </el-col>
    </el-row>

    <div v-if="!value.isOSSRegistered">
      <el-row :gutter="20">
        <el-col :span="12">
          <FormKit
            type="select"
            label="Desired registration date"
            name="desired.registration.startDate"
            placeholder="Select desired registration date"
            :options="[
              '01.01.2024',
              '01.04.2024',
              '01.07.2024',
              '01.10.2024',
              '01.01.2025',
              '01.04.2025',
              '01.07.2025',
              '01.10.2025',
            ]"
            validation="required"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <FormKit
            type="select"
            label="Is your company part of a VAT group?"
            name="part.of.vat.group"
            placeholder="Select ..."
            :options="['Yes', 'No']"
            validation="required"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <FormKit
            type="select"
            label="Is or was your company registered for IOSS?"
            name="registered.for.ioss"
            placeholder="Select ..."
            :options="['Yes', 'No']"
            validation="required"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <FormKit
            type="select"
            label="Is or was your company registered for MOSS?"
            name="registered.for.moss"
            placeholder="Select ..."
            :options="['Yes', 'No']"
            validation="required"
          />
        </el-col>
      </el-row>
    </div>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
