<script setup>
//import { defineProps, computed } from "vue";
//import { difference, includes, pickBy, union } from "lodash";
//import vatRegistrationCountries from "./vatRegistrationCountries.json";
//const props = defineProps(["formData"]);

/*const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);

const existingRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step09[
      "existing.VAT.registration.countries"
    ]["list"]
);

const existingIntrastatRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step14[
      "existing.Intrastat.registration.countries"
    ]["list"]
);

const desiredRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step11[
      "desired.VAT.registration.countries"
    ]["list"]
);

const desirableIntrastatRegistrationCountries = computed(() => {
  return pickBy(vatRegistrationCountries, (c, key) => {
    return (
      includes(
        difference(
        union(existingRegistrationCountries.value,desiredRegistrationCountries.value,[headOfficeCountry.value]),
          existingIntrastatRegistrationCountries.value,
        ),
        key
      )
    );
  })
});*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
//import CountryFlag from "vue-country-flag-next";
export default {
  name: "FeedbackForm",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="feedback"
    name="feedback"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Feedback</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="textarea"
          name="message"
          label="Feedback message"
          placeholder="Describe your experience of your onboarding with DutyPay."
        >
        </FormKit>
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
