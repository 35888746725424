<script setup>
import { to } from "await-to-js";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { interceptor } from "@dutypay/utilities";
const router = useRouter();
const formData = ref({});

async function submit(data) {
  const envStore = useEnvStore();
  console.log(data);
  let err, res;
  [err, res] = await to(
    interceptor.post(
      `${envStore.apiUrls.dutypay}/api/v0/onboarding-entries`,
      data
    )
  );
  if (err) {
    console.log(err);
    return;
  }
  localStorage.removeItem("onboardingForm-onboardingFormSteps");
  router.push("thankyou");
}
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
import AuthorizingPerson from "./OnboardingPage/AuthorizingPerson";
import AuthorizingPersonAddress from "./OnboardingPage/AuthorizingPersonAddress";
import BankInformation from "./OnboardingPage/BankInformation";
import ContactPerson from "./OnboardingPage/ContactPerson";
import CompanyInformation from "./OnboardingPage/CompanyInformation";
import CompanyAddress from "./OnboardingPage/CompanyAddress";
import ExistingRegistrationCountries from "./OnboardingPage/ExistingRegistrationCountries";
import ExistingRegistrationCountriesInformation from "./OnboardingPage/ExistingRegistrationCountriesInformation";
import ExistingIntrastatRegistrationCountries from "./OnboardingPage/ExistingIntrastatRegistrationCountries";
import ExistingIntrastatRegistrationCountriesInformation from "./OnboardingPage/ExistingIntrastatRegistrationCountriesInformation";
import LocalVATInformation from "./OnboardingPage/LocalVATInformation";
import LegalRepresentativePerson from "./OnboardingPage/LegalRepresentativePerson";
import RegistrationCountries from "./OnboardingPage/RegistrationCountries";
import RegistrationCountriesInformation from "./OnboardingPage/RegistrationCountriesInformation";
import OSSRegistration from "./OnboardingPage/OSSRegistration";
import IntrastatRegistrationCountries from "./OnboardingPage/IntrastatRegistrationCountries";
import IntrastatRegistrationCountriesInformation from "./OnboardingPage/IntrastatRegistrationCountriesInformation";
import PlasticTaxRegistrationCountries from "./OnboardingPage/PlasticTaxRegistrationCountries";
import PackagingRegisterCountries from "./OnboardingPage/PackagingRegisterCountries";
import FeedbackForm from "./OnboardingPage/FeedbackForm";
export default {
  name: "OnboardingPage",
  components: {
    AuthorizingPerson,
    AuthorizingPersonAddress,
    BankInformation,
    ContactPerson,
    CompanyInformation,
    CompanyAddress,
    LocalVATInformation,
    LegalRepresentativePerson,
    RegistrationCountries,
    RegistrationCountriesInformation,
    ExistingRegistrationCountries,
    ExistingRegistrationCountriesInformation,
    ExistingIntrastatRegistrationCountries,
    ExistingIntrastatRegistrationCountriesInformation,
    IntrastatRegistrationCountries,
    IntrastatRegistrationCountriesInformation,
    PlasticTaxRegistrationCountries,
    PackagingRegisterCountries,
    FeedbackForm,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <div>
    <FormKit
      type="form"
      :actions="false"
      name="onboarding-form"
      id="onboarding-form"
      v-model="formData"
      @submit="submit"
    >
      <FormKit
        use-local-storage
        type="multi-step"
        :allow-incomplete="false"
        tab-style="progress"
        :hide-progress-labels="true"
        name="onboardingFormSteps"
        id="onboardingFormSteps"
      >
        <FormKit type="step" name="step01" label="Company Information">
          <CompanyInformation v-bind:form-data="formData"></CompanyInformation>
        </FormKit>
        <FormKit type="step" name="step02" label="Company Address">
          <CompanyAddress v-bind:form-data="formData"></CompanyAddress>
        </FormKit>
        <FormKit type="step" name="step03" label="Local VAT Information">
          <LocalVATInformation
            v-bind:form-data="formData"
          ></LocalVATInformation>
        </FormKit>
        <FormKit type="step" name="step04" label="Legal Representative Person">
          <LegalRepresentativePerson
            v-bind:form-data="formData"
          ></LegalRepresentativePerson>
        </FormKit>
        <FormKit type="step" name="step05" label="Authorizing Person">
          <AuthorizingPerson v-bind:form-data="formData"></AuthorizingPerson>
        </FormKit>
        <FormKit type="step" name="step06" label="Authorizing Person Address">
          <AuthorizingPersonAddress
            v-bind:form-data="formData"
          ></AuthorizingPersonAddress>
        </FormKit>
        <FormKit type="step" name="step07" label="Contact Person">
          <ContactPerson v-bind:form-data="formData"></ContactPerson>
        </FormKit>
        <FormKit type="step" name="step08" label="Bank Information">
          <BankInformation v-bind:form-data="formData"></BankInformation>
        </FormKit>
        <FormKit
          type="step"
          name="step09"
          label="Countries with existing VAT Registration"
        >
          <ExistingRegistrationCountries
            v-bind:form-data="formData"
          ></ExistingRegistrationCountries>
        </FormKit>
        <FormKit
          type="step"
          name="step10"
          label="Additional Information for countries with existing VAT Registration"
        >
          <ExistingRegistrationCountriesInformation
            v-bind:form-data="formData"
          ></ExistingRegistrationCountriesInformation>
        </FormKit>
        <FormKit
          type="step"
          name="step11"
          label="Countries for VAT Registration"
        >
          <RegistrationCountries
            v-bind:form-data="formData"
          ></RegistrationCountries>
        </FormKit>
        <FormKit
          type="step"
          name="step12"
          label="Additional Information VAT Registration"
        >
          <RegistrationCountriesInformation
            v-bind:form-data="formData"
          ></RegistrationCountriesInformation>
        </FormKit>
        <FormKit type="step" name="step13" label="OSS Registration">
          <OSSRegistration v-bind:form-data="formData"></OSSRegistration>
        </FormKit>
        <FormKit
          type="step"
          name="step14"
          label="Countries with existing Intrastat Registration"
        >
          <ExistingIntrastatRegistrationCountries
            v-bind:form-data="formData"
          ></ExistingIntrastatRegistrationCountries>
        </FormKit>
        <FormKit
          type="step"
          name="step15"
          label="Additional Information for countries with existing Intrastat Registration"
        >
          <ExistingIntrastatRegistrationCountriesInformation
            v-bind:form-data="formData"
          ></ExistingIntrastatRegistrationCountriesInformation>
        </FormKit>
        <FormKit
          type="step"
          name="step16"
          label="Countries for planned Intrastat Registration"
        >
          <IntrastatRegistrationCountries
            v-bind:form-data="formData"
          ></IntrastatRegistrationCountries>
        </FormKit>
        <FormKit
          type="step"
          name="step17"
          label="Additional Information for countries for planned Intrastat Registration"
        >
          <IntrastatRegistrationCountriesInformation
            v-bind:form-data="formData"
          ></IntrastatRegistrationCountriesInformation>
        </FormKit>
        <FormKit
          type="step"
          name="step18"
          label="Plastic tax registration in spain"
        >
          <PlasticTaxRegistrationCountries
            v-bind:form-data="formData"
          ></PlasticTaxRegistrationCountries>
        </FormKit>
        <FormKit
          type="step"
          name="step19"
          label="Packaging-register registration in spain"
        >
          <PackagingRegisterCountries
            v-bind:form-data="formData"
          ></PackagingRegisterCountries>
        </FormKit>
        <FormKit
          type="step"
          name="step20"
          label="Feedback"
        >
          <FeedbackForm
            v-bind:form-data="formData"
          ></FeedbackForm>
          <template #stepNext>
            <FormKit type="submit" />
          </template>
        </FormKit>
      </FormKit>
    </FormKit>
  </div>
</template>

<style lang="scss">
#onboarding-form > .formkit-outer {
  width: 80%;
  margin: 0 auto;
}
#onboarding-form > .formkit-outer > .formkit-wrapper {
  width: 100% !important;
  max-width: 100%;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
