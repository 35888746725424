<script setup>
import { defineProps, computed } from "vue";
import vatRegistrationCountries from "./vatRegistrationCountries.json";
const props = defineProps(["formData"]);
const desiredIntrastatRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step16["desired.Intrastat.registration.countries"]["list"]
);
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
import CountryFlag from "vue-country-flag-next";
//import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import IntrastatRegistrationCountryInformation from "./IntrastatRegistrationCountriesInformation/IntrastatRegistrationCountryInformation";
export default {
  name: "IntrastatRegistrationCountriesInformation",
  components: {
    IntrastatRegistrationCountryInformation,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {
    /*includesCountry: (countryCode, countriesArray) => {
      return includes(countriesArray, countryCode);
    }*/
  },
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="desired.Intrastat.registration.countries"
    name="desired.Intrastat.registration.countries"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          Additional Information for Countries with planned Intrastat Registration
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isEmpty(desiredIntrastatRegistrationCountries)">
      <el-col :span="24">
        <el-alert 
          title="No countries with planned Intrastat registrations selected"
          type="warning"
          show-icon
          :closable="false"
          description="Please select at least one country to receive a quote for Intrastat registration.">
        </el-alert>
        <el-alert 
          title="Note for Intrastat registrations"
          type="error"
          show-icon
          :closable="false"
          description="For a country to be available for Intrastat registration, there needs to be an existing or planned VAT registration.">
        </el-alert>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-for="(country, index) in desiredIntrastatRegistrationCountries" :key="index">
      <el-col :span="24">
        <h2><country-flag :country="country" size="normal" style="border: 1px solid #000; margin-bottom: -11px;"></country-flag> &nbsp; {{vatRegistrationCountries[country]}}</h2>
        <IntrastatRegistrationCountryInformation v-bind:country="country"></IntrastatRegistrationCountryInformation>
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss">
.el-alert {
  margin: 0 0 20px;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
