<script setup>
import { defineProps, computed } from "vue";
import {  includes,  union } from "lodash";
//import vatRegistrationCountries from "./vatRegistrationCountries.json";
const props = defineProps(["formData"]);

const existingRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step09[
      "existing.VAT.registration.countries"
    ]["list"]
);

const desiredRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step11[
      "desired.VAT.registration.countries"
    ]["list"]
);

const isPlasticTaxRegistrationPossible = computed(() => {
  return includes(union(existingRegistrationCountries.value, desiredRegistrationCountries.value), "ES");
});
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
//import CountryFlag from "vue-country-flag-next";
export default {
  name: "IntrastatRegistrationCountries",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="desired.plastic.tax.registration.ES"
    name="desired.plastic.tax.registration.ES"
    #default="{ value }"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Plastic tax registration in Spain</h1>
      </el-col>
    </el-row>
    <el-row v-if="!isPlasticTaxRegistrationPossible">
      <el-col>
        <el-alert 
          title="Plastic Tax registration in Spain"
          type="warning"
          show-icon
          :closable="false"
          description="Please select Spain as a country with an existing or planned VAT registration to receive an offer for plastic tax registration in spain.">
        </el-alert>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isPlasticTaxRegistrationPossible">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="Do you plan a plastic tax registration in spain?"
          name="plastic.tax.registration.ES.isDesired"
          id="plastic.tax.registration.ES.isDesired"
        >
        </FormKit>
      </el-col>
      <el-col :span="6" v-if="value['plastic.tax.registration.ES.isDesired']">
        <FormKit
          type="date"
          label="Desired registration date for plastic tax in spain"
          name="plastic.tax.registration.ES.registration.startDate"
          id="plastic.tax.registration.ES.registration.startDate"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss">
.el-alert {
  margin: 0 0 20px;
}
</style>


<i18n>
  {
  "en-US": {
  }
  }
</i18n>
