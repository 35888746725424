import { en } from "@formkit/i18n";
import { genesisIcons } from "@formkit/icons";
import {
  createMultiStepPlugin,
  createLocalStoragePlugin,
} from "@formkit/addons";
import { createProPlugin, inputs } from "@formkit/pro";

const isCheckboxAndRadioMultiple = (node) =>
  (node.props.type === "checkbox" || node.props.type === "radio") &&
  node.props.options;

function addAsteriskPlugin(node) {
  node.on("created", () => {
    const isRequired = node.props.parsedRules.some(
      (rule) => rule.name === "required"
    );
    if (!isRequired) return;

    const isMultiOption = isCheckboxAndRadioMultiple(node);

    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an
    // invalid cached schema.
    node.props.definition.schemaMemoKey = `required_${
      isMultiOption ? "multi_" : ""
    }${node.props.definition.schemaMemoKey}`;

    const schemaFn = node.props.definition.schema;
    node.props.definition.schema = (sectionsSchema = {}) => {
      if (isRequired) {
        if (isMultiOption) {
          sectionsSchema.legend = {
            children: ["$label", "*"],
          };
        } else {
          sectionsSchema.label = {
            children: ["$label", "*"],
          };
        }
      }
      return schemaFn(sectionsSchema);
    };
  });
}

const pro = createProPlugin(process.env.VUE_APP_FORMKIT_KEY, inputs);

const config = {
  locales: { en },
  locale: "en",
  icons: {
    ...genesisIcons,
  },
  plugins: [
    createMultiStepPlugin(),
    addAsteriskPlugin,
    pro,
    createLocalStoragePlugin({
      // plugin defaults:
      prefix: "onboardingForm",
      key: undefined,
      control: undefined,
      maxAge: 31536000000, // 1 hour
      debounce: 200,
      beforeSave: undefined,
      beforeLoad: undefined,
    }),
  ],
};

export default config;
