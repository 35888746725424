<script setup>
import { defineProps} from "vue";
import legalForms from "./legalforms.json";
import countries from "./countries.json";
import filter from "lodash/filter"
const props = defineProps(["formData"]);

function usedLegalFormsLoader({search}){
  let country = props.formData.onboardingFormSteps.step01["company.information"]["head.office.country"];
  if(!country){
    return [];
  } else {
      if(!search){
        return legalForms[country]
      } else {
        return filter(legalForms[country], (a) =>  {
          return new RegExp(search, 'gi').test(a)});
      }
  }
}
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "CompanyInformation",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {
  },
  async mounted() {},
};
</script>

<template>
  <FormKit 
    type="group" 
    id="company.information" 
    name="company.information"
    >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>General company information</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Legal name"
          name="name.legal"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="text"
          label="Designation"
          name="name.designation"
          validation=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="10">
        <FormKit
          type="autocomplete"
          label="Head office country"
          name="head.office.country"
          id="head.office.country"
          placeholder="Select the country"
          @input="$formkit.reset('legal.form');$formkit.reset('head.office.region')"
          clear-search-on-open
          :options="countries"
          validation="required"
        />
      </el-col>
      <el-col :span="10">
        <FormKit
          type="autocomplete"
          label="Legal form"
          name="legal.form"
          id="legal.form"
          placeholder="Select the legal form"
          clear-search-on-open
          :options="usedLegalFormsLoader"
          validation="required"
        />
      </el-col>
      <el-col :span="4">
        <FormKit
          type="date"
          label="Formation date"
          name="formation.date"
          id="formation.date"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="text"
          label="Company website"
          name="company.website"
          id="company.website"
          placeholder="https://www.example.com..."
          help="Or seller profile on marketplace"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <FormKit
          type="text"
          label="Trade registry no."
          name="trade.register.number"
          validation=""
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="text"
          label="Registry court"
          name="trade.register.court"
          validation=""
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="date"
          label="Date of first registry entry"
          name="trade.register.first.date"
          id="trade.register.first.date"
          validation=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="textarea"
          rows="10"
          label="Company description"
          placeholder="Please describe the products and sales channels of your company,"
          name="description"
          id="description"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
