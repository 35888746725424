<template>
  <el-config-provider :locale="elementLocale" :zIndex="3000">
    <div
      class="menubar"
      style="
        background-color: #000;
        height: 50px;
        width: 100%;
        text-align: center;
      "
    >
      <img
        :src="`${apiUrls.dutypay}/api/v0/assets/logo/dutypay/standard/light/svg`"
        :alt="applicationVariable"
        class="header-bar__application-logo speak-never"
        style="height: 30px; padding: 10px; width: auto"
      />
    </div>
    <div style="width: 100%; max-width:1200px; margin: 0 auto 1rem;">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script setup>
import { onBeforeMount, computed /*, defineAsyncComponent*/ } from "vue";
import { ElConfigProvider } from "element-plus";
import {
  //useAuthStore,
  //useCompanyStore,
  useEnvStore,
} from "@dutypay/store-modules";
import elementLocales from "@dutypay/components-library/src/locales/element-plus";
import { useI18n } from "vue-i18n";
const envStore = useEnvStore();
//const ReminderSelectCompany = defineAsyncComponent(() => import('@dutypay/settings-pages/src/reminder/ReminderSelectCompany'))

const { locale } = useI18n({ useScope: "global" });
const elementLocale = computed(() => {
  return elementLocales[locale.value];
});

async function initApp() {
  envStore.setGlobalLoading();
  await envStore.fetchHosts();
  envStore.releaseGlobalLoading();
}

onBeforeMount(async () => {
  await initApp();
});
</script>

<script>
import { mapState } from "pinia";
export default {
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<style lang="scss">
$--color-primary: #5392bc;
@import "~@dutypay/design-system/src/scss_legacy/index.scss"; // TODO: Legacy scss. Can be removed when the last relicts of element are gone.
</style>

<i18n>
{}
</i18n>
