import OnboardingPage from "./pages/OnboardingPage";
import ThankyouPage from "./pages/ThankyouPage";


const pages = [
    {
      path: '/',
      redirect: '/onboarding',
    },

    {
        path: '/onboarding',
        name: 'OnboardingPage',
        component: OnboardingPage,
    },

    {
      path: '/thankyou',
      name: 'ThankyouPage',
      component: ThankyouPage,
    },

  // {
  //   path: '/infrastructure-components',
  //   name: 'InfrastructureComponents',
  //   component: InfrastructureComponents,
  //   redirect: '/infrastructure-components/list',
  //   children: [
  //     {
  //       path: '/infrastructure-components/list',
  //       name: 'InfrastructureComponentList',
  //       component: InfrastructureComponentList
  //     },
  //     {
  //       path: '/infrastructure-components/:id',
  //       name: 'InfrastructureComponentDetails',
  //       component: InfrastructureComponentDetails,
  //       props: true
  //     }
  //   ]
  // },
  // {
  //   path: '/assets',
  //   name: 'HubSystemAssets',
  //   component: HubSystemAssets,
    // redirect: '/assets/images',
  //   children: [
  //     {
  //       path: '/assets/images',
  //       component: ImageAssets
  //     },
  //     {
  //       path: '/assets/font',
  //       component: FontAssets
  //     }
  //   ]
  // },

  // {
  //   path: '/assets',
  //   name: 'Assets',
  //   component: Assets,
  //   redirect: '/assets/images',
  //   children: [
  //     {
  //       path: '/assets/images',
  //       name: 'AssetImageList',
  //       component: AssetImageList
  //     },
  //     {
  //       path: '/assets/images/:id',
  //       name: 'AssetImageDetails',
  //       component: AssetImageDetails,
  //       props: true
  //     },
  //     {
  //       path: '/assets/fonts',
  //       name: 'AssetFontList',
  //       component: AssetFontList
  //     },
  //   ]
  //},
];

import {computed} from 'vue';
//import { i18n } from './i18n';
//const { t } = i18n.global;

const menuItems = computed(() => [
          // {
          //   slug: 'infrastructure-components',
          //   path: '/infrastructure-components',
          //   name: 'InfrastructureComponents',
          //   label: t('menuItems.infrastructureComponents.linkLabel'),
          //   /*children: [
          //     {
          //       path: '/reports/overview',
          //       name: 'ListReports',
          //       label: this.$t('menuItems.reports.children.overview.linkLabel')
          //     }
          //   ]*/
          // },
          // {
          //   slug: 'images',
          //   path: '/assets/images',
          //   name: 'Images',
          //   label: t('menuItems.images.linkLabel'),
          // },
          // {
          //   slug: 'fonts',
          //   path: '/assets/fonts',
          //   name: 'Fonts',
          //   label: t('menuItems.fonts.linkLabel'),
          // }
        ]);

    
export {
    pages,
    menuItems
}