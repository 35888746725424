<script setup>
/*import { defineProps} from "vue";
const props = defineProps(["formData"]);
const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "LocalVATInformation",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="local.vat.information"
    name="local.vat.information"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Local VAT Information</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <FormKit
          type="text"
          label="Local VAT ID"
          name="local.vat.id"
          validation=""
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="date"
          label="Local VAT ID valid from"
          name="local.vat.id.validity.startDate"
          id="local.vat.id.validity.startDate"
          validation=""
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Local TAX ID"
          name="local.tax.id"
          validation=""
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="date"
          label="Local TAX ID valid from"
          name="local.tax.id.validity.startDate"
          id="local.tax.id.validity.startDate"
          validation=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Responsible local tax authority"
          name="local.tax.authority"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
