<script setup>
import { defineProps } from "vue";
//const props = defineProps(["formData"]);
const iterationCountry = defineProps(["country"]);

/*const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "ExistingRegistrationCountryInformation",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    :id="'existing.registration.countries.' + iterationCountry.country"
    :name="'existing.registration.countries.' + iterationCountry.country"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Local Tax ID"
          name="local.tax.id"
          placeholder="Local Tax ID"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="date"
          label="Valid from:"
          name="local.tax.id.valid.from"
          id="local.tax.id.valid.from"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Local VAT ID"
          name="local.VAT.id"
          placeholder="Local Tax ID"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="date"
          label="Valid from:"
          name="local.VAT.id.valid.from"
          id="local.VAT.id.valid.from"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
