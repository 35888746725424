<script setup>
import { defineProps } from "vue";
//const props = defineProps(["formData"]);
const iterationCountry = defineProps(["country"]);

/*const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "ExistingIntrastatRegistrationCountryInformation",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    :id="'existing.Intrastat.registration.countries.' + iterationCountry.country"
    :name="'existing.Intrastat.registration.countries.' + iterationCountry.country"
    #default="{ value }"
  >

    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="Registered for import?"
          name="import.isRegistered"
          id="import.isRegistered"
          :validation="value['export.isRegistered'] ? '' : 'required'"
        />
      </el-col>
      <el-col :span="6" v-if="value['import.isRegistered']">
        <FormKit
          type="date"
          label="Registered for import since"
          name="import.registration.startDate"
          id="import.registration.startDate"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="Registered for export?"
          name="export.isRegistered"
          id="export.isRegistered"
          :validation="value['import.isRegistered'] ? '' : 'required'"
        />
      </el-col>
      <el-col :span="6" v-if="value['export.isRegistered']">
        <FormKit
          type="date"
          label="Registered for export since"
          name="export.registration.startDate"
          id="export.registration.startDate"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
