<script setup>
import { defineProps, computed } from "vue";
import vatRegistrationCountries from "./vatRegistrationCountries.json";
const props = defineProps(["formData"]);
const existingIntrastatRegistrationCountries = computed(
  () =>
    props.formData.onboardingFormSteps.step14["existing.Intrastat.registration.countries"]["list"]
);
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
import CountryFlag from "vue-country-flag-next";
//import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import ExistingIntrastatRegistrationCountryInformation from "./ExistingIntrastatRegistrationCountriesInformation/ExistingIntrastatRegistrationCountryInformation";
export default {
  name: "ExistingIntrastatRegistrationCountriesInformation",
  components: {
    ExistingIntrastatRegistrationCountryInformation,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {
    /*includesCountry: (countryCode, countriesArray) => {
      return includes(countriesArray, countryCode);
    }*/
  },
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="existing.Intrastat.registration.countries"
    name="existing.Intrastat.registration.countries"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>
          Additional Information for Countries with existing Intrastat Registration
        </h1>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="isEmpty(existingIntrastatRegistrationCountries)">
      <el-col :span="24">
        <el-alert 
          title="No countries with existing Intrastat registrations selected"
          type="warning"
          show-icon
          :closable="false"
          description="Please select at least one country to receive a quote for Intrastat submission.">
        </el-alert>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-for="(country, index) in existingIntrastatRegistrationCountries" :key="index">
      <el-col :span="24">
        <h2><country-flag :country="country" size="normal" style="border: 1px solid #000; margin-bottom: -11px;"></country-flag> &nbsp; {{vatRegistrationCountries[country]}}</h2>
        <ExistingIntrastatRegistrationCountryInformation v-bind:country="country"></ExistingIntrastatRegistrationCountryInformation>
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss">
.el-alert {
  margin: 0 0 20px;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
