<script setup>
import { defineProps, computed } from "vue";
import countries from "./countries.json";
import regions from "./regions.json";
import filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
const props = defineProps(["formData"]);
const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);

const regionsTest = computed(
  () => {
    let country = props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ];
    return regions[country] ? regions[country] : [];
  }
);

function usedRegions({search}){
  let country = props.formData.onboardingFormSteps.step01["company.information"]["head.office.country"];
  if(!country){
    return [];
  } else {
      if(!search){
        return regions[country] ? regions[country] : []
      } else {
        if(!regions[country] ){
          return [];
        }
        return filter(regions[country], (a) =>  {
          return new RegExp(search, 'gi').test(a)});
      }
  }
}
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "CompanyAddress",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="company.address"
    name="company.address"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Company address</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="text"
          label="Street"
          name="street.name"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Street No."
          name="street.number"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Additional info"
          name="street.additional.info"
          validation=""
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4">
        <FormKit
          type="text"
          label="Zip code"
          name="zip.code"
          validation="required"
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="text"
          label="City"
          name="city.name"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="select"
          label="Head office country"
          placeholder="Select the country"
          name="head.office.country"
          disabled
          :options="countries"
          v-model="headOfficeCountry"
          validation="required"
        />
      </el-col>
      <el-col :span="12" v-if="!isEmpty(regionsTest)">
        <FormKit
          type="autocomplete"
          label="Head office region"
          placeholder="Select the region"
          name="head.office.region"
          id="head.office.region"
          :options="usedRegions"
          clear-search-on-open
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="tel"
          label="Phone number"
          name="phone.number"
          validation="required"
        >
        </FormKit>
      </el-col>
      <el-col :span="12">
        <FormKit
          type="email"
          label="Company E-Mail"
          name="primary.email"
          validation=""
        >
        </FormKit>
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
