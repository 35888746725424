<script setup>
/*import { defineProps, computed } from "vue";
const props = defineProps(["formData"]);
const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "LegalRepresentativePerson",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="legal.representative.person"
    name="legal.representative.person"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Legal Representative Person</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <FormKit
          type="select"
          label="Form of address"
          placeholder="Select..."
          name="form.of.address"
          :options="['Mr.', 'Mrs.']"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="First name"
          name="name.given"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Middle names"
          name="name.additional"
          validation=""
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Last name"
          name="name.family"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
