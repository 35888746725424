<script setup>
import { defineProps } from "vue";
import currencies from "../currencies.json";
//const props = defineProps(["formData"]);
const iterationCountry = defineProps(["country"]);

/*const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "RegistrationCountryInformation",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {
    isNumber: function(event) {
      if (!/^[0-9]+$/.test(event.key) || event.key === '.') return event.preventDefault();
    }
  },
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    :id="'registration.countries.' + iterationCountry.country"
    :name="'registration.countries.' + iterationCountry.country"
    #default="{ value }"
  >
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="date"
          label="Desired registration date"
          name="registration.desired.startDate"
          id="registration.desired.startDate"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="select"
          label="Reason for registration"
          name="registration.reason"
          placeholder="Select reason for registration"
          :options="[
            'Warehouse usage',
            'Business premise',
            'Exceeding distance selling threshold',
            'Voluntary registration',
          ]"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="date"
          label="Begin warehouse usage"
          name="warehouse.usage.startDate"
          id="warehouse.usage.startDate"
          validation="required"
          v-if="value['registration.reason'] == 'Warehouse usage'"
        />
        <FormKit
          type="date"
          label="Exceeded distance selling threshold on"
          name="exceeded.distanceselling.threshold.startDate"
          id="exceeded.distanceselling.threshold.startDate"
          validation="required"
          v-if="value['registration.reason'] == 'Exceeding distance selling threshold'"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="number"
          :help="'Net in whole ' + currencies[iterationCountry.country]"
          label="Turnover in the previous year of planned registration"
          name="turnover.registration.previous.year.amount"
          @keypress="isNumber($event)"
          step="1"
          validation="required"
        />
      </el-col>
      </el-row>
      <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="number"
          :help="'Net in whole ' + currencies[iterationCountry.country] + ' - Estimates are sufficient'"
          label="Turnover in the year of planned registration"
          name="turnover.registration.year.amount"
          @keypress="isNumber($event)"
          step="1"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="number"
          :help="'Net in whole ' + currencies[iterationCountry.country] + ' - Estimates are sufficient'"
          label="Turnover in the following year of planned registration"
          name="turnover.registration.following.year.amount"
          @keypress="isNumber($event)"
          step="1"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="iterationCountry.country == 'PL'">
        <el-col :span="12">
        <FormKit
          type="text"
          label="First Name of mother of authorizing person"
          name="authorizing.person.mother.name.given"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="text"
          label="Last Name of mother of authorizing person"
          name="authorizing.person.mother.name.family"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20" v-if="iterationCountry.country == 'PL'">
        <el-col :span="12">
        <FormKit
          type="text"
          label="First Name of father of authorizing person"
          name="authorizing.person.father.name.given"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="text"
          label="Last Name of father of authorizing person"
          name="authorizing.person.father.name.family"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
