<script setup>
/*import { defineProps, computed } from "vue";
const props = defineProps(["formData"]);
const headOfficeCountry = computed(
  () =>
    props.formData.onboardingFormSteps.step01["company.information"][
      "head.office.country"
    ]
);*/
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "ContactPerson",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="contact.person"
    name="contact.person"
    #default="{ value }"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>7. Contact Person</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="checkbox"
          label="Contact person differs from authorizing person."
          help="Check if you want to enter another person to be DutyPay's primary contact in the company."
          name="isDifferentFromAuthorizingPerson"
          id="isDifferentFromAuthorizingPerson"
          :value="false"
        />
      </el-col>
    </el-row>
    <div v-if="value.isDifferentFromAuthorizingPerson">
      <el-row :gutter="20">
        <el-col :span="6">
          <FormKit
            type="select"
            label="Form of address"
            placeholder="Select..."
            name="form.of.address"
            :options="['Mr.', 'Mrs.']"
            validation="required"
          />
        </el-col>
        <el-col :span="9">
          <FormKit
            type="text"
            label="First name"
            name="name.given"
            validation="required"
          />
        </el-col>
        <el-col :span="9">
          <FormKit
            type="text"
            label="Last name"
            name="name.family"
            validation="required"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <FormKit
            type="select"
            label="Company position"
            placeholder="Select..."
            name="position"
            :options="[
              'Owner',
              'Managing Director',
              'Board Member',
              'Accountant',
              'Controller',
              'Employee',
            ]"
            validation="required"
          />
        </el-col>
        <el-col :span="8">
          <FormKit
            type="tel"
            label="Phone number"
            name="phone.number"
            validation="required"
          >
          </FormKit>
        </el-col>
        <el-col :span="8">
          <FormKit
            type="email"
            label="E-Mail"
            name="email"
            validation=""
          >
          </FormKit>
        </el-col>
      </el-row>
    </div>
  </FormKit>
</template>

<style scoped lang="scss"></style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
