<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "ThankyouPage",
  components: {

  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {
  },
  async mounted() {},
};
</script>

<template>
  <div>
    <h1>Thank you for submitting your onboarding data.</h1>
  </div>
</template>

<style lang="scss">
#onboarding-form > .formkit-outer {
  width: 80%;
  margin: 0 auto;
}
#onboarding-form > .formkit-outer > .formkit-wrapper {
  width: 100% !important;
  max-width: 100%;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
