<script setup>
//import { defineProps } from "vue";
import countries from "./countries.json";
//const props = defineProps(["formData"]);
</script>

<script>
//import { to } from "await-to-js";
import { mapState } from "pinia";
import { useEnvStore } from "@dutypay/store-modules";
export default {
  name: "AuthorizingPerson",
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useEnvStore, ["apiUrls"]),
  },
  methods: {},
  async mounted() {},
};
</script>

<template>
  <FormKit
    type="group"
    id="authorizing.person"
    name="authorizing.person"
  >
    <el-row :gutter="20">
      <el-col :span="24">
        <h1>Authorizing Person</h1>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-alert
          title="Notice"
          type="info"
          description="This information will be used to create the power of attorney. "
          show-icon
          :closable="false"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <FormKit
          type="select"
          label="Form of address"
          placeholder="Select..."
          name="form.of.address"
          :options="['Mr.', 'Mrs.']"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="First name"
          name="name.given"
          validation="required"
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Middle names"
          name="name.additional"
          validation=""
        />
      </el-col>
      <el-col :span="6">
        <FormKit
          type="text"
          label="Last name"
          name="name.family"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <FormKit
          type="select"
          label="Marital status"
          placeholder="Select..."
          name="marital.status"
          :options="['Married', 'Unmarried', 'Divorced', 'Widowed', 'Engaged']"
          validation="required"
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="select"
          label="Company position"
          placeholder="Select..."
          name="position"
          :options="['Owner', 'Managing Director', 'Board Member']"
          validation="required"
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="text"
          label="Personal Tax ID"
          name="personal.tax.id"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6">
        <FormKit
          type="date"
          label="Date of birth"
          name="date.of.birth"
          id="date.of.birth"
          validation="required"
        />
      </el-col>
      <el-col :span="9">
        <FormKit
          type="text"
          label="City of birth"
          name="city.of.birth"
          validation="required"
        />
      </el-col>
      <el-col :span="9">
        <FormKit
          type="autocomplete"
          label="Country of birth"
          name="country.of.birth"
          id="country.of.birth"
          placeholder="Select the country"
          :options="countries"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <FormKit
          type="select"
          label="Identification document type"
          name="identification.document.type"
          id="identification.document.type"
          placeholder="Select..."
          :options="['Passport', 'Local ID Card']"
          validation="required"
          help="This document is used for verification with the authorities."
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="text"
          label="Identification document number"
          name="identification.document.number"
          validation="required"
          help="This document is used for verification with the authorities."
        />
      </el-col>
      <el-col :span="8">
        <FormKit
          type="autocomplete"
          label="Countries of citizenship"
          name="countries.of.citizenship"
          id="countries.of.citizenship"
          placeholder="Select one or more countries"
          :options="countries"
          multiple
          clear-search-on-open
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <FormKit
          type="date"
          label="Identification document date of issue"
          name="identification.document.issue.date"
          id="identification.document.issue.date"
          validation="required"
        />
      </el-col>
      <el-col :span="12">
        <FormKit
          type="date"
          label="Identification document expiration date"
          name="identification.document.expiration.date"
          id="identification.document.expiration.date"
          validation="required"
        />
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <FormKit
          type="select"
          label="Type of power of representation"
          name="power.of.representation.type"
          id="power.of.representation.type"
          placeholder="Select..."
          :options="[
            'Sole power of representation - You are solely authorized to sign for the company.',
            'Collective power or representation - You are only authorized to sign for the company with other persons.',
          ]"
          validation="required"
        />
      </el-col>
    </el-row>
  </FormKit>
</template>

<style scoped lang="scss">
.el-alert {
  margin: 0 0 20px;
}
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
